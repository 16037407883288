<template>
  <!-- 意见反馈 -->
  <div class="content">
    <div class="textarea">
      <div class="textInputFlex">
        <el-input v-filteremoji type="textarea" resize="none" placeholder="请填写你的功能建议，感谢你的支持~(必填)" v-model="textarea" maxlength="100" show-word-limit :autosize="{ minRows: 8, maxRows: 10 }">
        </el-input>
      </div>
    </div>
    <div class="bottomSunmit">
      <el-button @click="suggestionsave" style="width:167px" type="primary">提交</el-button>
    </div>
  </div>
</template>
<script>
import { Message } from 'element-ui'
import { suggestionsave } from '@/api/employeedata.js'
export default {
  name: 'personalCenter-opinion',
  data() {
    return {
      textarea: ''
    }
  },
  methods: {
    suggestionsave() {
      if (this.textarea) {
        let data = {
          content: this.textarea
        }
        suggestionsave(data).then(res => {
          if (res && res.code === '000000') {
            Message({
              message: '意见提交成功！',
              type: 'success'
            })
          } else {
            Message({
              message: '意见提交失败！',
              type: 'error'
            })
          }
        })
      } else {
        Message({
          message: '请填写意见！！！',
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  display: inline-block;
  height: 100%;
  .textarea {
    width: calc(100% - 60px);
    height: calc(100% - 104px);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    overflow: auto;
    background: white;
    .textInputFlex {
      width: calc(100% - 60px);
      margin-left: 30px;
      margin-top: 30px;
      height: 200px;
      font-size: 14px !important;
    }
  }
  .bottomSunmit {
    width: 100%;
    height: 64px;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
